import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'firebase/auth';
import 'firebase/firestore';
import Head from 'next/head';
import Error from 'pages/_error';
import CastProvider from 'react-chromecast';
import { ErrorBoundary } from 'react-error-boundary';
import '../styles/index.css';

Sentry.init({
  dsn: 'https://10459333be7146d9b889112b2f621f45@o472866.ingest.sentry.io/5507095',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <title>Streaming</title>
      </Head>
      <ErrorBoundary FallbackComponent={Error}>
        <CastProvider>
          <Component {...pageProps} />
        </CastProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
