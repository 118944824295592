import Image from 'next/image';

export default function CustomError() {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-gray-50">
      <div className="flex flex-col items-center justify-center p-3" role="alert">
        <Image
          src="/images/brokentv.jpg"
          width={500}
          height={250}
          className="w-full mx-auto border-8 border-gray-900 rounded-md shadow-2xl md:w-1/2"
        />
        <h1 className="mt-3 text-4xl font-bold md:text-6xl">Ups!</h1>
        <h3 className="text-xl text-center md:text-3xl">Ocurrió un error</h3>
      </div>
    </div>
  );
}
